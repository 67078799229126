import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Container, MainHeading } from '../../globalStyles';
import { HeroVideo, HeroSection, HeroText, ButtonWrapper, HeroButton } from './HeroStyles';

const Hero = () => {
  return (
    <HeroSection>
        <HeroVideo src="./assets/hero.mp4" autoPlay muted loop />
        <Container>
            <MainHeading>Grow Your Career With Us</MainHeading>
            <HeroText>
                We Provide the best Career Oriented Courses for all Kind of People's and all Over the Tamilnadu.
            </HeroText>
            <ButtonWrapper>
                <Link to="enquiry">
                    <Button>Enquiry</Button>
                </Link>
                <HeroButton href='https://forms.gle/tP3ZJ5guAtGzNLkg9' target='_blank'>Register</HeroButton>
            </ButtonWrapper>
        </Container>
    </HeroSection>
  );
};

export default Hero;