import React from 'react';
import Carousel from '../components/Carousel/Carousel';
import { Content } from '../components/Content/Content';
import Features from '../components/Features/Features';
import Hero from '../components/Hero/Hero';
import Form from '../components/Form/ContactForm';
import { heroOne, heroTwo, heroThree } from '../data/HeroData';

//  Hero Feature Content Carousel

export const Home = () => {
  return (
    <>
      <Hero />;
      <Features />;
      <Content {...heroOne} />
			<Content {...heroTwo} />
			<Content {...heroThree} />
			<Carousel />
      <Form />
    </>
  );
};

export default Home;