export const data = [
	{
		to: '/',
		text: 'Home',
		id: 'home',
	},
	{
		to: '/',
		text: 'Features',
		id: 'about',
	},
	{
		to: '/',
		text: 'Courses',
		id: 'courses',
	},
	{
		to: '/',
		text: 'Programs',
		id: 'programs',
	},
	{
		to: '/',
		text: 'Contact Us',
		id: 'contactus',
	},
];
