import React from 'react';
import { Container, MainHeading } from '../../globalStyles';
import { RegisterVideo, RegisterSection, RegisterText, ButtonWrapper, RegisterButton } from './RegisterCourseStyles';

const Register = () => {
  return (
    <RegisterSection>
        <RegisterVideo src="./assets/hero.mp4" autoPlay muted loop />
        <Container>
            <MainHeading>Register Your Course With Us</MainHeading>
            <RegisterText>
                We are Providing a Technology Courses via Online From any where any time any People.
            </RegisterText>
            <ButtonWrapper>
                <RegisterButton href='https://forms.gle/tP3ZJ5guAtGzNLkg9' target='_blank'>Click Here To Register Your Course</RegisterButton>
            </ButtonWrapper>
        </Container>
    </RegisterSection>
  );
};

export default Register;