import React from 'react';
import GlobalStyle from './globalStyles';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';

//Pages
import Home from './pages/Home';
import Enquiry from './pages/EnquiryPage';
import RegisterCourse from './pages/RegisterCourse'

function App() {
  return (
    <Router>
      <GlobalStyle />
      <Navbar />
      <Routes>
        <Route path='/' exact element = {<Home/>} />
        <Route path='/enquiry' exact element = {<Enquiry/>} />
        <Route path='/Register-Course' exact element = {<RegisterCourse/>} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
